/* GUTTER */


/* 40px gutter */

.gutter-40.row {
    margin-right: -20px;
    margin-left: -20px;
}

.gutter-40 > [class^="col-"],
.gutter-40 > [class^=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
}


/* 20px gutter */

.gutter-20.row {
    margin-right: -10px;
    margin-left: -10px;
}

.gutter-20 > [class^="col-"],
.gutter-20 > [class^=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
}


/* 10px gutter */

.gutter-10.row {
    margin-right: -5px;
    margin-left: -5px;
}

.gutter-10 > [class^="col-"],
.gutter-10 > [class^=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

/* 5px gutter */

.gutter-5.row {
    margin-right: -2px;
    margin-left: -2px;
}

.gutter-5 > [class^="col-"],
.gutter-5 > [class^=" col-"] {
    padding-right: 2px;
    padding-left: 2px;
}

/* 0px gutter */

.gutter-0.row {
    margin-right: 0;
    margin-left: 0;
}

.gutter-0 > [class^="col-"],
.gutter-0 > [class^=" col-"] {
    padding-right: 0;
    padding-left: 0;
}
