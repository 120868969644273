@font-face {
    font-family: 'Bauer Bodoni Std 1';
    src: url('../fonts/custom/BauerBodoniStd-Roman.eot');
    src: url('../fonts/custom/BauerBodoniStd-Roman.eot?#iefix') format('embedded-opentype'),
        url('../fonts/custom/BauerBodoniStd-Roman.woff2') format('woff2'),
        url('../fonts/custom/BauerBodoniStd-Roman.woff') format('woff'),
        url('../fonts/custom/BauerBodoniStd-Roman.ttf') format('truetype'),
        url('../fonts/custom/BauerBodoniStd-Roman.svg#BauerBodoniStd-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Book';
    src: url('../fonts/custom/Gotham-Book.eot');
    src: url('../fonts/custom/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/custom/Gotham-Book.woff2') format('woff2'),
        url('../fonts/custom/Gotham-Book.woff') format('woff'),
        url('../fonts/custom/Gotham-Book.ttf') format('truetype'),
        url('../fonts/custom/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/custom/Gotham-Black.eot');
    src: url('../fonts/custom/Gotham-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/custom/Gotham-Black.woff2') format('woff2'),
        url('../fonts/custom/Gotham-Black.woff') format('woff'),
        url('../fonts/custom/Gotham-Black.ttf') format('truetype'),
        url('../fonts/custom/Gotham-Black.svg#Gotham-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/custom/Gotham-Light.eot');
    src: url('../fonts/custom/Gotham-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/custom/Gotham-Light.woff2') format('woff2'),
        url('../fonts/custom/Gotham-Light.woff') format('woff'),
        url('../fonts/custom/Gotham-Light.ttf') format('truetype'),
        url('../fonts/custom/Gotham-Light.svg#Gotham-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/custom/Gotham-Bold.eot');
    src: url('../fonts/custom/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/custom/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/custom/Gotham-Bold.woff') format('woff'),
        url('../fonts/custom/Gotham-Bold.ttf') format('truetype'),
        url('../fonts/custom/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/custom/Gotham-Medium.eot');
    src: url('../fonts/custom/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/custom/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/custom/Gotham-Medium.woff') format('woff'),
        url('../fonts/custom/Gotham-Medium.ttf') format('truetype'),
        url('../fonts/custom/Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}