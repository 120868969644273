// Import the mixins
// @import "style/mixins/css3-mixins"; // sudah tidak diperlukan lagi karena kita menggunakan autoprefixer di gulp kita, penulisannya sama dengan css biasa
// Import other
@import "style/font-face";
@import "style/base";
@import "style/gutter";

body {
    color: $base-color;
    font-family: 'Gotham';
    font-weight: 300;
}

a {
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.ft-light {
    font-weight: 300;
}

.ft-book {
    font-family: 'Gotham Book';
    font-weight: normal;
}

.ft-black {
    font-weight: 900;
}

.ft-bold {
    font-weight: bold;
}

.ft-medium {
    font-weight: 500;
}

.green {
    color: $green;
}

.white {
    color: $white;
}

.pink {
    color: $pink;
}

.brown {
    color: $brown;
}

.light-brown {
    color: $light-brown;
}

.heading-line {
    border-top: 1px solid #f7b58a;
    border-bottom: 1px solid #f7b58a;
    padding: 20px 0;
    margin-top: 40px;
    margin-bottom: 32px;
    font-size: 28px;
}

.heading {
    margin-top: 40px;
    margin-bottom: 32px;
    font-size: 28px;
}

.title {
    font-size: 20px;
}

.page-title {
    margin-top: 292px;
    font-size: 68px;
    color: $white;
}

#page-heading {
    height: 506px;
    // margin-bottom: 54px;
}

.img-center {
    margin: 0 auto;
}

.img-wrapper {
    overflow: hidden;
    position: relative;
}

.bg-img {
    background-size: cover;
    background-position: center center;
}

.caption {
    /* position: absolute; */
    background-color: $white-9;
    bottom: 0;
    p {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.bg-soft-pink {
    background-color: #fbede4;
}

.btn-pink {
    font-size: 20px;
    font-weight: 900;
    color: $white;
    padding: 15px 16px;
    width: 218px;
    border-color: #d69775;
    border-radius: 4px;
    background: linear-gradient(to bottom, #e59f78, #d69775);
    &:hover,
    &:focus {
        color: $white;
        border-color: $pink;
        background: $pink;
    }
}

// akan di generate dengan autoprefixer
.flex {
    display: flex;
}

.navbar-main {
    background-color: $white-8;
    border: 0;
    min-height: 174px;
    margin-bottom: 0;
    .navbar-brand {
        padding: 31px 15px 15px 15px;
        img {
            width: 308px;
        }
    }
    .nav-menu {
        float: right;
        margin-top: 53px;
        &> li {
            display: flex;
            &> a {
                font-weight: 500;
                color: $grey;
                padding: 15px 24px;
                &:hover,
                &:focus {
                    color: $green;
                }
            }
            &:after {
                content: "|";
                padding: 15px 0;
            }
            &:last-child {
                &:after {
                    content: " ";
                }
            }
        }
        &> .active {
            &> a {
                color: $green;
                background-color: transparent;
                &:hover,
                &:focus {
                    color: $green;
                    background-color: transparent;
                }
            }
        }
        &.lang {
            margin-top: 0;
            &> .open {
                &>a {
                    background-color: $white-5;
                }
                &> .dropdown-menu {
                    right: 0;
                    left: auto;
                    background-color: $white-5;
                    border: 0;
                    border-radius: 0;
                    &> li {
                        &> a {
                            &:hover,
                            &:focus {
                                color: $green;
                            }
                        }
                    }
                }
            }
        }
    }
}

.bg-line {
    background-color: $pink;
    .bg-img {
        height: 8px;
    }
}

/* Special class on .container surrounding .navbar, used for positioning it into place. */

.navbar-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 20;
}

.navbar-wrapper>.container {
    padding-right: 0;
    padding-left: 0;
}

.navbar-wrapper .navbar {
    padding-right: 15px;
    padding-left: 15px;
}

#about-home {
    .heading-line {
        margin-bottom: 40px;
    }
    .box-item {
        margin-bottom: 36px;
        .title {
            margin-top: 25px;
            margin-bottom: 20px;
        }
        p {
            line-height: 26px;
            margin-bottom: 20px;
        }
    }
    .img-wrapper {
        .bg-img {
            height: 350px;
        }
    }
}

#services {
    padding-bottom: 52px;
    .box-item {
        .title {
            margin-top: 18px;
            margin-bottom: 0;
        }
    }
}

#ig-feed {
    padding-bottom: 42px;
    .bg-img {
        height: 204px;
    }
}

.content {
    border-bottom: 2px solid #efcebd;
    padding-bottom: 32px;
    margin-bottom: 36px;
    .title {
        color: #da9676;
        font-size: 22px;
        margin-top: 46px;
        margin-bottom: 36px;
    }
    p {
        line-height: 26px;
        margin-bottom: 0;
    }
    .compare {
        margin-top: 32px;
        margin-bottom: 40px;
    }
}

.sidebar {
    background-color: #f9ede7;
    .title-menu {
        font-size: 28px;
        padding-top: 34px;
        padding-bottom: 18px;
        margin-bottom: 0;
        margin-top: 0;
        border-bottom: 1px solid #f7b489;
    }
    .sidebar-menu {
        margin: 0;
        padding: 0;
        list-style-type: none;
        &> li {
            position: relative;
            &> a {
                padding: 20px 42px;
                color: #555555;
                border-radius: 0;
                &:hover,
                &:focus {
                    font-weight: bold;
                    background-color: #fdfaf9;
                    &:before {
                        content: '\f0da';
                        font-family: 'FontAwesome';
                        position: absolute;
                        z-index: 9;
                        top: 50%;
                        left: 22px;
                        font-size: 24px;
                        transform: translateY(-50%);
                    }
                }
            }
            &.active {
                &> a {
                    font-weight: bold;
                    background-color: #fdfaf9;
                    &:hover,
                    &:focus {
                        color: #555555;
                    }
                    &:before {
                        content: '\f0da';
                        font-family: 'FontAwesome';
                        position: absolute;
                        z-index: 9;
                        top: 50%;
                        left: 22px;
                        font-size: 24px;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}

.contact-form {
    // padding-top: 20px;
    .form-group {
        margin-bottom: 28px;
        label {
            color: #736357;
            font-weight: 500;
            font-size: 20px;
            letter-spacing: 2px;
        }
        .form-control {
            border-radius: 0;
            border: 1px solid #c5c5c5;
            height: 58px;
        }
        textarea {
            &.form-control {
                height: auto;
            }
        }
        .btn {
            margin-top: 6px;
            float: right;
        }
    }
}

.address {
    .title {
        // margin-top: 46px;
        color: #736357;
        font-size: 30px;
        margin-bottom: 24px;
    }
    p {
        // font-size: 20px;
        font-size: 16px;
        margin-bottom: 50px;
        a {
            text-decoration: underline;
            color: $base-color;
            &:hover,
            &:focus {
                color: $green;
            }
        }
    }
}

.maps {
    height: 534px;
    margin-bottom: 40px;
    border: 2px solid #cccccc;
}

.footer-top {
    background-color: #e19d77;
    h5 {
        font-size: 16px;
        margin-top: 42px;
        margin-bottom: 16px;
        color: $black;
    }
    p {
        margin-bottom: 34px;
        line-height: 26px;
        color: $black;
        a {
            color: $black;
            &:hover,
            &:focus {
                color: $green;
            }
        }
    }
    .sosmed {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            li {
                display: inline-block;
                margin-right: 44px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    font-size: 26px;
                    color: $black;
                    &:hover,
                    &:focus {
                        color: $green;
                    }
                }
            }
        }
    }
}

.copyright {
    background-color: $black;
    padding: 17px 0 10px;
    p {
        color: $white;
        font-size: 11px;
        margin: 0;
    }
}

// home-slider owl-carousel

#home-slider {
    // margin-bottom: 14px;
    .container {
        position: relative;
    }
    .carousel-caption {
        text-align: left;
        left: 15px;
        right: 0;
        top: 206px;
        text-shadow: none;
        h1 {
            font-size: 70px;
            span {
                font-size: 75px;
                display: block;
            }
        }
    }
    .bg-img {
        height: 614px;
    }
    .owl-dots .owl-dot {
        span {
            width: 24px;
            height: 24px;
            background: transparent;
            border: 3px solid $white;
        }
        &.active {
            span {
                background: $white;
            }
        }
    }
}

#compare {
    padding-bottom: 44px;
}

#compare-slider {
    padding: 0 186px;
}

.compare-box {
    border: 4px solid #ffd7bc;
    .twentytwenty-overlay {
        &:hover {
            background: none;
        }
    }
    .twentytwenty-handle {
        background-color: $black-5;
    }
    .twentytwenty-before-label,
    .twentytwenty-after-label {
        opacity: 1;
        &:before {
            background-color: $white-7;
            color: #736357;
            font-size: 20px;
            font-family: 'Gotham Book';
            letter-spacing: 0;
            top: 282px;
        }
    }
}

#video {
    padding-bottom: 60px;
}

#video-slider {
    padding: 0 97px;
    .item {
        background-color: transparent;
    }
    .item-video {
        height: 337px;
        border: 4px solid #bcbcbc;
        .owl-video-play-icon {
            background: none;
            background-color: $white-6;
            border-radius: 50%;
            &:before {
                content: "\f04b";
                font-family: 'FontAwesome';
                color: $white;
                font-size: 30px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .title {
        margin-top: 38px;
    }
    p {
        margin: 0;
        line-height: 26px;
    }
}

#treatments-page {
    margin-top: 40px;
}

#page-slider {
    .bg-img {
        height: 415px;
    }
    .owl-dots .owl-dot {
        span {
            width: 15px;
            height: 15px;
            background: transparent;
            border: 3px solid $white;
        }
        &.active {
            span {
                background: $white;
            }
        }
    }
}

#testimonials-slider {
    padding: 0 77px;
    margin-bottom: 48px;
    .item {
        background-color: transparent;
    }
    .bg-img {
        height: 202px;
        width: 202px;
        margin: 0 auto;
        border-radius: 50%;
        border: 4px solid #b2b2b2;
    }
    .testi {
        font-size: 18px;
        color: #736357;
        line-height: 32px;
        margin-top: 26px;
        font-family: 'Bauer Bodoni Std 1';
        font-style: italic;
    }
    .owl-nav [class*='owl-'] {
        top: 20%;
    }
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
/* Carousel base class */

.carousel {
    height: 768px;
    margin-bottom: 60px;
}

/* Since positioning the image, we need to help out the caption */

.carousel-caption {
    z-index: 10;
}

/* Declare heights because of positioning of img element */

.carousel .item {
    height: 768px;
    background-color: #777;
}

.carousel-inner>.item>img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 768px;
}

.owl-carousel .item {
    background-color: #777;
}

.owl-theme .owl-stage>.item>img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
}

.owl-theme .owl-nav {
    margin-top: 0;
}

.owl-theme .owl-nav [class*='owl-'] {
    position: absolute;
    z-index: 100;
    top: 33%;
    font-weight: 100;
    font-size: 80px;
    color: #636363;
    background-color: transparent;
    margin: 0;
    padding: 0;
    &:hover {
        color: $grey;
        background-color: transparent;
    }
}

.owl-theme .owl-nav>.owl-prev {
    left: 0;
}

.owl-theme .owl-nav>.owl-next {
    right: 0;
}

.owl-theme .owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    z-index: 100;
}


/* jasny custom
-------------------------------------------------- */

.offcanvas.in {
    top: 118px;
    width: 100%;
    border: 0;
    background-color: $white;
}

.navbar-mobile {
    .nav-mobile {
        &> li {
            &> a {
                font-weight: 500;
                color: $grey;
                &:hover,
                &:focus {
                    color: $green;
                }
            }
        }
        &> .active {
            &> a {
                color: $green;
                background-color: transparent;
                &:hover,
                &:focus {
                    color: $green;
                    background-color: transparent;
                }
            }
        }
        .divider {
            height: 1px;
            margin: 9px 1px;
            overflow: hidden;
            background-color: #b8b8b8;
        }
        .open .dropdown-menu > li > a {
            padding: 5px 15px;
        }
        & > .open > a,
        & > .open > a:hover,
        & > .open > a:focus {
            color: $green;
        }
    }
}


#about {
    margin: 40px 0;
}
.team-member {
    text-align: center;
    img { width: 100%; }
    .image { margin-bottom: 20px; }
    .title {
        color: $green;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .position {
        color: #636363;
    }
    margin-bottom: 40px;
}

.career {
    .benefits {
        .box {
            color: $green;
            i {
                font-size: 60px;
            }
            margin-bottom: 30px;
        }
        margin: 30px 0 0;
    }
}