$base-color:    #111111;

$grey:          #7d7d7d;

$green:         #12a89d;
$pink:          #f7b284;

$light-brown:   #998675;
$brown:         #534741;

$white:         #ffffff;
$white-5:       rgba(255, 255, 255, .5);
$white-6:       rgba(255, 255, 255, .6);
$white-7:       rgba(255, 255, 255, .7);
$white-8:       rgba(255, 255, 255, .8);
$white-9:       rgba(255, 255, 255, .9);
$black:         #000000;
$black-1:       rgba(0, 0, 0, .1);
$black-2:       rgba(0, 0, 0, .2);
$black-4:       rgba(0, 0, 0, .4);
$black-5:       rgba(0, 0, 0, .5);
$black-6:       rgba(0, 0, 0, .6);
$black-8:       rgba(0, 0, 0, .8);

// 5 columns layout
.col-xs-20,
.col-sm-20,
.col-md-20,
.col-lg-20 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-20 {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-20 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-20 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-20 {
        width: 20%;
        float: left;
    }
}

@mixin clearfix() {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}
